export let data = [
  '{"name": "Snehal Bari", "place": "Handewadi, Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/277d683334e71b002528583642b1fa984d6e3811df5c6e5b3eac073a258dfffa/IMG_20210625_175513_087.jpg", "scribbleSpace": "I\'m a yoga enthusiast. Passionate about learning and teaching .  Getting up early at 5 has changed my lifestyle more towards healthy being and it connects me to the nature. Looking forward to stay with this habit lifelong to achieve more than I thought \\ud83d\\ude00.", "contribution": "I can share details of Yogic lifestyle easy to adapt\\ud83d\\udcab\\ud83e\\udd18", "intro": "Yoga Trainer"}',
  '{"name": "Hema Iyer", "place": "Thane", "waNumber": "919049148884", "email": "hemaiyerb@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5f621d686f7e9e16bb97080f9d9b92e97fbfcf33739a9d1ced0d61f1e8f9bb16/facebook_1615349494345_6775266845531428237.jpg", "scribbleSpace": "hey 5am\'ers! Excited to be here and really dont know what to expect, so I am open to the activities that this group does.\\nI like doing the usual stuff that most people do - movies, spending time with friends and family(sometimes), Netflix (courtesy Lockdown). \\nI am interested in traveling, working out, going for walks, learning languages (I can speak 4 languages right now & want to increase that count), writing (sometimes)\\nworkwise I am a German language specialist and work in a publishing company - currently online", "contribution": "I\'ll take it as it comes, but can help for German language related stuff", "intro": "Optimistic and Positive"}',
  '{"name": "Thiyagesh Prabu S", "place": "Chennai", "waNumber": "918056274740", "email": "thiyageshprabu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b0161a2dfe0ea31ee307087aa0f55cebd629898da7540fd793a6f4ac41b853e/IMG_20210224_203256.jpg", "scribbleSpace": "Chartered accountant. Playing violin, Volunteering with Ngo to teach underprivileged kids", "contribution": "Play violin & offer Personal finance ideas", "intro": "Motivator and Tamil poet"}',
  '{"name": "Manisha Bhujbal", "place": "Balajinagar pune", "waNumber": "918208503607", "email": "manishaabhujbal882@gmail.com", "photoLine": "https://api.typeform.com/responses/files/14a2a1a878e4d7f6356eb3187b0cb43f40c8f58882b3541a7c3c6ac5f171fc34/IMG_20210615_122942.jpg", "scribbleSpace": "I like music and reading and help to people", "contribution": "Yes", "intro": "I am teachar"}',
  '{"name": "Satyajit Shinde", "place": "Powai, Mumbai", "waNumber": "919869321812", "email": "shinde.satya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/570b5ef08fb1c97e6def281fc96be9bed6490eee96f7dd3c7066f103bc75e60f/20210214_164634.jpg", "scribbleSpace": "IntereseBirding, Wildlife Photography", "contribution": "Na", "intro": "Bird Photographer"}',
  '{"name": "Sandeep Karande", "place": "Brahmand, Thane", "waNumber": "919619903224", "email": "karandesandeep@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f4b2a91f5b54c36292644395990d8212b5d288584c594bc2c2af88fe5003968a/DSC_9324.jpg", "scribbleSpace": "I enjoy trekking, bikes rides and nature conservation", "contribution": "How treks can help you explore a new self.", "intro": "Nature Lover"}',
  '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/f5f9dce4ca9b2018fe92ebab91d05e4def3a14e211d0809716617caa548a45a2/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional based out of Gurugram. Married. Occasional Quizzer. US Politics and Cooking are my hobbies. Learning Resume writing in my free time. Planning to host a course on Udemy on Quizzing in the near future. Interested to learn Spanish and know more about Latin American culture.", "contribution": "I can speak about US Political System and US Elections. I can also speak about Esperanto (a language I tried learning recently). I can also run a general quiz if needed. I can also speak about HR in general.", "intro": "Curious Learner"}',
  '{"name": "Yamini. D", "place": "Chennai", "waNumber": "919940112513", "email": "yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d7abeca884983ab52711d698867adf0496f8aa6bd7f9178c90f68827001e49c2/20190914_155737.jpg", "scribbleSpace": "Fitness freak and nature lover", "contribution": "Positive thoughts", "intro": "Nature and bird lover"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Kavitha Reddy", "place": "Hyderabad", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3bea44dda7357c6d900eec31029c3507c037e162824c51603e3a38d2267e5595/Screenshot_2021_0630_190954.png", "scribbleSpace": "Interested in knowing new things, like playing sports, good @cooking", "contribution": "Can share life experience s", "intro": "Interested in knowing new things, like playing sports, good @cooking"}',
  '{"name": "Shrravane Vuyyuru", "place": "Chennai", "waNumber": "919884983213", "email": "shrravane21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ab2e8e825bf478608eb71b72409b71667270836ed5594bf4eb113f16f54a851b/C925DFB5_B1C3_45C0_AAF6_EA4C50BB6EEA.jpeg", "scribbleSpace": "Just another happy go lucky girl, finding new ways to become the best version of myself. Also, someone with an appetite for art.", "contribution": "Just looking forward to learn from you guys for now!", "intro": "CA aspirant!"}',
  '{"name": "Manisha Bhosale", "place": "Kothrud Pune", "waNumber": "919890039898", "email": "manisha_bhosale@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/9a427193746158a1672f8b21968fe1a5e8a2eb90d1386ffcad3e326d78f7796f/678892E1_5F9C_4189_9BB6_F9C77ECEDF3A.png", "scribbleSpace": "Certified yoga instructor. Yoga has been an interest for many years. Indulged more after quitting my job as a fruitful activity. Went on for certifications as the interest in the science grew. \\nMoving towards healthy living journey has been following yogic practices, participating and following the HealthyMe programme by Pallavi Dhoot and now trying to bring some discipline by getting into the habit of early rising", "contribution": "I could take one on one sessions for those having queries related to the yogasanas/ pranayama/ shuddhi kriya", "intro": "Yoga practitioner"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Preethi", "place": "Bangalore", "waNumber": "917406000927", "email": "preethi1991g@gmail.com", "photoLine": "https://api.typeform.com/responses/files/40186777333ba5429ce3ed94faa8256728bc18f65676185b51bf922fcd10f327/IMG_20210630_175413.jpg", "scribbleSpace": "Music, cooking, content creator", "contribution": "No", "intro": "Wellness Enthusiast , Coach, Momprenuer"}',
  '{"name": "Dipti Patil", "place": "Wakad pune", "waNumber": "918830397209", "email": "diptipatil3485@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3e696e15ed51eb02e329c971099c4e56362a3931402bdeed3050bccef9aa9b3f/IMG_20210412_172615.jpg", "scribbleSpace": "Cooking", "contribution": "No", "intro": "Doctorate in chemistry"}',
  '{"name": "Amogh Chougule", "place": "Sinhagad Road, Pune", "waNumber": "919890696063", "email": "amoghsc@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9b795eb39bda12832aff97e0eac81e6fd71b6aa92e26c502e1291366cec7bb15/9F0D384C_6BEC_4ED3_BC24_70FD8D1E579F.jpeg", "scribbleSpace": "Jack of many, master of some. Here for growth mindset community & accountability", "contribution": "Can share some insights about personal growth. Areas of interests psychology, human nature, relationships, conflict resolution, creative thinking, embracing discomfort, etc...", "intro": "Curious creative"}',
  '{"name": "Harsh Ramteke", "place": "Nagpur", "waNumber": "919823671410", "email": "harshramtekeindia@gamil.com", "photoLine": "https://api.typeform.com/responses/files/6f5e62038d361c438aa238c22ab79aa0d069b382986e6d0777ebe66cf212c94c/IMG_20200629_112551.jpg", "scribbleSpace": "Interests- Developing, Designing, sharing life lessons and storytelling.\\n\\nHobbies- Painting, reading stories.\\n\\nPlans- As I love designing and developing i am planing to launch my own design firm.\\n\\nI belive each one of us has something to offer to this world. Your success is not decided by your accomplishments but by how many lives you have touched.", "contribution": "I would be happy to share my life experience, stories that inspires me, and my paintings.", "intro": "Artist"}',
  '{"name": "Balaji Mankari", "place": "Chandra Layout, Banglore", "waNumber": "917275656561", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a2d7707cca80b159c2d0b7a706e51c964d67559f3f0a70a7202ad6a81a7cdcf4/A8332870_CAA5_4261_842C_CF5EDF046D77.jpeg", "scribbleSpace": "Road trips, trekking, Farming, Teaching, Writing journals, Bike and Car enthusiast, Interested in International politics and relations.", "contribution": "Mediation, and about international politics", "intro": "Voyager"}',
  '{"name": "Neha Sharma", "place": "Kota, Rajasthan", "waNumber": "918000317261", "email": "neha17shrm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1d3ca1d6516b12934f7bb01afd34d528310ec8df3c78a61df1e115ac0575a1a4/Screenshot_20210630_215149_2.png", "scribbleSpace": "Dancer, painter, roller-skater, hula-hooper, and practicing kindness consciously.", "contribution": "Impromptu short paint /draw session.", "intro": "Abstract painter"}',
  '{"name": "Anusha Yeruva", "place": "Hyd, Telangana", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e45a8e63cc630139af3f0d8f9ef450a6d0f497d93bf5fc0b078d7605cfea4efe/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living. I love nature walks, take fresh air and organic diet, which helps me to be fit and healthy. I am looking forward to know myself and my intrests with the help of this club.", "contribution": "I can discuss on sustainabke living and its future benifit", "intro": "Yoga practitioner and a minimalist"}',
  '{"name": "Leena Patil", "place": "Mulund Mumbai", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/601c573570d7a96d5ef3fa854711a7e962bb1b66550958462db9c326eb7a3496/IMG_20210506_WA0045.jpg", "scribbleSpace": "I am a happy  go lucky person. Loves to spead happiness everywhere!", "contribution": "Happy to help always", "intro": "Lawyer!"}',
  '{"name": "Shilpi", "place": "Mahalunge Pune", "waNumber": "918698342681", "email": "shilpi.kumari26@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e2663a655423b248802ad7aac0c0ffa1488f9b481f4804797e6c873020c3dc5b/1530612583325.jpg", "scribbleSpace": "Doing yoga, meditation, staying fit, singing, writing, sketching", "contribution": "Experience of waking up early", "intro": "Singer"}',
  '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyash.nagayach@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d34718becfbb22cf350392afe2e89078ccc5f860c2c5f16ede299769db9d973/IMG_8329.JPG", "scribbleSpace": "I am avid sports lover, like reading books (mostly non-fiction) and photography is more than merely an interest. The biggest expectation in life is to continuosly become better version of self. Being a night owl all my life, I decided to take up the challenge to wake up early and whoa I have somehow conquered it and discovered about myself more than ever before. Nights are wonderful but believe me Mornings are pure bliss.", "contribution": "I can offer sharing different perspectives from the world of sports. Also, I would like sharing the philosophical poetry during the daily interactions.", "intro": "Pragmatic Believer"}',
  '{"name": "Debanjana Sinha", "place": "Bangalore", "waNumber": "919911777199", "email": "debu.piyu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/21b0c903f4bca10a4d35493d84a6cb64fc6900ab0a691f455c3fdc61fd24a4e1/IMG_20190913_184013.jpg", "scribbleSpace": "Stationery, fountain pens, Stoicism, Travelling, Japan", "contribution": "Stoicism, philosophy, digital marketing, fountain pens", "intro": "Memento Mori"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Snehal Gandhi", "place": "Wagholi Pune", "waNumber": "918698093357", "email": "snehal.gandhi10dec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2ef6d546ef7781b840e3bc05850d8a411b406ed0caf485bdf7a20dbcbb906cee/4D873851_925B_4141_AA99_370CF69CCCEF.jpeg", "scribbleSpace": "Dancing listening to soft music", "contribution": "I m an introvert person I don\\u2019t like to talk to unknown people that\\u2019s why I m participating in 5am club to come out off my comfortzone", "intro": "Photographer"}',
  '{"name": "Purvi Savla", "place": "Salisbury Park", "waNumber": "918369285163", "email": "purvisavla2016@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f5dabcfd7971fe0f4e8533e42303d8a027d160656bfef514dde937dd09a86162/IMG_20210626_170049.jpg", "scribbleSpace": "I Struggle to sleep, struggle to wake up. I want to change the tag - I am not a morning person", "contribution": "I can show how to make calming zentangles. No prior drawing skills required.", "intro": "Optimistic Wolf, Enthu Entrepreneur, Single Sassy Mummy"}',
  '{"name": "Prashant", "place": "Dholpur Rajasthan", "waNumber": "918005629466", "email": "pacific.goyal@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f13ff8f67af792d7aa0563180ccc4a0c3d3032aeab1e21c3e0a820bafb99c64f/IMG_20191022_164255_536.jpg", "scribbleSpace": "Religiously follow Cricket, loves cooking, gardening", "contribution": "I may try to make other people laugh", "intro": "I am a bollywood kind of person"}',
  '{"name": "Aparna Kanade", "place": "Katraj", "waNumber": "919922043187", "email": "aparnagkanade@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1bfeeea6a422ef31834a9a59beabcb699c9434abb912aacddc58c8f14635d1ae/IMG_20200816_183020_Bokeh_01.jpeg", "scribbleSpace": "I love exploring places, humans, books and cuisines.", "contribution": "I am considerably good at drawing, handcraft, languages(Marathi, English and Spanish) and cooking according to people that know me. Would love to share any knowledge in the same areas.", "intro": "Explorer"}',
  '{"name": "Seema Kalantri", "place": "Bopal Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/fadd501212aa1930e2e04bc7b35404df19751a1d6ab937ef24a8398715195934/IMG_20201212_082556.jpg", "scribbleSpace": "Learner", "contribution": "Good listening Ears", "intro": "Software Professional"}',
  '{"name": "Vikrant Patil", "place": "Kudawale, Dapoli", "waNumber": "918552969377", "email": "vikrant.patil@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be4ea364bedc0b44c3d0200bc3da32197a5ee1b002bcb18a06bf746368fadd93/vikrant.jpeg", "scribbleSpace": "I can say that mostly I live life. During free time I teach programming, sometimes develop small scale software,  read, play music , help people live happy family life (counselling). As part of living life I spend a lot of time talking to my wife and my daughter, cook food, roam around the river, grow food in the small land that we have, maintain my mud house and trees that I have planted and wonder about the secrets of nature around me.", "contribution": "Health, food, yoga, Relationship Counselling", "intro": "A friend"}',
  '{"name": "Anu Prabhakar", "place": "BARC, Mumbai", "waNumber": "919820160969", "email": "prabhakaranu93@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebebb56b65f4318d9b91334a195d44e96bf409bb27600dbe4442f809320d3d7c/IMG_20200412_WA0004.jpg", "scribbleSpace": "With the start of this program I want to be make finish maximum of my non office duties in morning such as exercise, yoga. \\nPlan to journal my daily morning update for these 21 days. \\nGoal to be more morning friendly", "contribution": "Maybe some exercise tips", "intro": "Ambivert, love nature, athlete"}',
  '{"name": "Siddharth Biniwale", "place": "Ganesh Mala, Sinhagad road Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dbe063adf4940a04a78b4bd017942ef43a6bb0e79fc6c26ba4650772e9bbc491/IMG_20210630_071039.jpg", "scribbleSpace": "I am a Naturalist. Exploring wilderness and understanding ecosystems is a way of life for me. I Birdwatching, wildlife photography interests me a lot. I enjoy listening to the birds and recording bird calls. Currently working on a research project on Avian Bioacuostics (Sounds of birds) in different forest habitats.", "contribution": "a) Tips for wildlife/Bird photography\\n\\nb) DIY tricks to make Bird nests from trash", "intro": "Storyteller, Naturalist"}',
  '{"name": "Saurabh Agrawal", "place": "Raipur", "waNumber": "918602100608", "email": "srbhagrawal14@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f17ef5b8bb5576ea42db3a857e302fe35ddefae1ca399ed64f4e82ed5795e61d/PicsArt_06_14_11.51.07.jpg", "scribbleSpace": "Progressing to achieve something. Banker by Profession. Like listening music, solving problems.", "contribution": "I am novice to share anything.", "intro": "Banker, reader"}',
  '{"name": "Poorvi Sharma", "place": "Pune", "waNumber": "919167335120", "email": "poorvi.sharma31@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6c4f8936321c71f51a870e9daedd6d0bb241a410d014611a75d1364fb87b7838/20210620_185318.jpg", "scribbleSpace": "When away from work, I enjoy abstract painting and find immense pleasure in bringing her imaginative senses to the canvas.", "contribution": "Haven\'t thought through, but can teach abstract painting.", "intro": "Product Marketing Person, Painter"}',
  '{"name": "Kavita", "place": "Karvenagar, Pune", "waNumber": "917972264580", "email": "kpalve18@gmail.com", "photoLine": "https://api.typeform.com/responses/files/92fea4306a72c867f5bed18839482da3ba46a551b7627fda1251caf06ebf9826/IMG_20210630_184037.jpg", "scribbleSpace": "No", "contribution": "No", "intro": "badminton player"}',
  '{"name": "Gaurangi Patil", "place": "Mundhwa", "waNumber": "917447335879", "email": "gaurangilad2013@gmail.com", "photoLine": "https://api.typeform.com/responses/files/70a4f729b340556cdcc6aeb71eebb22cbbdfce081142752fe3e78ce6c2e44266/IMG_20190817_WA0034.jpg", "scribbleSpace": "Love to meditate....very lazy", "contribution": "Nothing", "intro": "Full time homemaker"}',
  '{"name": "Bipin Advant", "place": "Bavdhan Pune", "waNumber": "919881491130", "email": "bipinadvant@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0812841b8ee5debf0c45f8cb0b51efe97bebefabdda55805fd21163381928960/IMG_20210530_234901.jpg", "scribbleSpace": "Music, positive thinking.\\nLooking for life long habit of waking up early", "contribution": "Power of gratitude. Some good music links", "intro": "IT professional"}',
  '{"name": "Vidya patil", "place": "Katraj pune", "waNumber": "917350014341", "email": "vidyaspatil7@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f38f9be8bd937a584551b44910d36a4fa9627e71b97ca0ab54aed576a33075fc/IMG_20171120_201411.jpg", "scribbleSpace": "Listening music, doing yoga, travelling,want learn sining", "contribution": "Some yoga and health related messages", "intro": "home Baker"}',
  '{"name": "Pallavi Dhoot", "place": "Pune, Senapati Bapat Road", "waNumber": "919822008007", "email": "pallavidhoot@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/481eb608bce182acc4df23d94aae588e4f8c34ad3e573cb78f2d5b430c9ec35e/16250665649071373088638.jpg", "scribbleSpace": "I am a homemaker mother.\\nA researcher by \\nnature; passionate about nature, environment, health and spirituality. I facilitate a Naturopathy Awareness and Practice Program by the name \'Healthy Me\'", "contribution": "I would love to share about health, nutrition, natural healing, medicine free life & about parenting", "intro": "An Avid Explorer"}',
  '{"name": "Charuta", "place": "Sus road pune", "waNumber": "919822012622", "email": "charuta.gaikwad@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b16a28c23303fd9c3838a669c2613f99868061dd90edc13206a5c89d0e179f9/IMG_20210318_225138.jpg", "scribbleSpace": "I am a Mirror, safe - trustworthy place to reflect yourself and feel powerful, free, beautiful, happier than before.", "contribution": "How to live the life at the fullest without expectations", "intro": "Life itself"}',
  '{"name": "Prashant Dighe", "place": "Warje, Pune", "waNumber": "919763707605", "email": "prashantdighe1495@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0951a2ddfec967dd37c0d168e9ab263148355baa37fa87329202992b7e2d3a34/IMG_20180909_201003_043.jpg", "scribbleSpace": "Listening music, travelling", "contribution": "Experience", "intro": "Sports"}',
  '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d13157bc1f10044a791a66034d0115fc8c222ba173244197e12c86a2932c90a4/Snapchat_641092485.jpg", "scribbleSpace": "Art, fitness enthusiast, badminton, learning a new language, music", "contribution": "Sanskrit and learning from Bagavat Gita, Ramayana, Mahabarata", "intro": "Enthusiastic Learner"}',
  '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b44e03ec40a118aa68550ad82ef06ea7cf8127d29a4deaea3cd50a22e9c5c7aa/20210123_075025.jpg", "scribbleSpace": "Learning yoga and on the path to understand spirituality.", "contribution": "Podcasting for beginners", "intro": "Software Engineer"}',
  '{"name": "Prateema", "place": "Pratema bidar", "waNumber": "917019425099", "email": "prateemarajole@gmail.com", "photoLine": "https://api.typeform.com/responses/files/346915eb40b415acfe48f2c20ba50b33c4a40f5d5bac3a909b579c9a81afb9e2/IMG_20181110_WA0003.jpg", "scribbleSpace": "Hobby listening music", "contribution": "Experience", "intro": "Melophile"}',
  '{"name": "Vishal Patil", "place": "Dhankawadi", "waNumber": "919923002980", "email": "vpatil717@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0f9945be0723cf7e786f7e0bfc64f4083d95b033271e9c265a33bbe43b0047d6/IMG_20210629_113322.jpg", "scribbleSpace": "Cricket", "contribution": "Life is beautiful", "intro": "Cricket commentator"}',
  '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
  '{"name": "Preetee", "place": "Aundh, Pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f0089291d000657537ce06b45abe4469980c244e5feab05e46c6af4149206653/WhatsApp_Image_2021_06_26_at_23.20.25.jpeg", "scribbleSpace": "I am a book. I let the reader read me. Contents of the book: Meditation, Yog, Reading, Writing, Biodiversity, Gardening, Education, Parenting, Mindfulness, Water conservation, Soil conservation, Environment Education", "contribution": "Eco friendly parenting, Screen free parenting, Eating habits, Terrace Gardening, Lazy composting", "intro": "Nature observer"}',
  '{"name": "Ashish Sharma", "place": "Malad East-Mumbai", "waNumber": "919619730766", "email": "ashish.at.iit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9f0cb9e10c7165dcc7c7fa4dd5b212236ed3e4ff026eb363a0a2afe4da70fd02/1AB42CCD_8F6C_4D78_BB87_CF9D4EB9FA00.jpeg", "scribbleSpace": "Sports, outdoor activities, looking forward to regulate life", "contribution": "Not sure as of now.", "intro": "Yoga Practitioner"}',
  '{"name": "Pranav Patil", "place": "Parvati Pune", "waNumber": "919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc949309ee0e158a2e044e0a403d861dcd7e48f6f6869dc5f0ee51124395bd9f/Screenshot_20210624_162326__01.jpg", "scribbleSpace": "Psychologist body-builder, INFJ, Counsellor-Mentor-Advisor \\ud83d\\ude07, Scientist, Astrology-curious, Work-out-holic \\ud83d\\udcaa, Nasha-positive person", "contribution": "1. How to be happy 2. Micro- workouts at home 3. Make good tea 4. Socializing tips 5. Tinker on piano", "intro": "Armwrestling enthusiast"}',
  '{"name": "Vidya kedari", "place": "Katraj pune", "waNumber": "919850975989", "email": "vidya.ukedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/803a2ca75ea85ca70aa5f5e45eacf1e79976c8ccce562cdee8129aefc738b15b/20210619_151910.jpg", "scribbleSpace": "Yoga", "contribution": "Yes", "intro": "Yoga practitioner"}',
  '{"name": "Shrikant Thorat", "place": "Bibwewadi Pune", "waNumber": "919890393742", "email": "shreekant@cathorat.com", "photoLine": "https://api.typeform.com/responses/files/9d0fb328aede1f850c81421f8de55306e713945ca1059bd7c78847380494642f/A84547B3_A65F_4060_9F5B_E120C089E188.jpeg", "scribbleSpace": "I want to always remain fit and want to get successful by inculcating good habits , I believe that every has good knowledge, only those succeed who have the power to keep knowledge updated and have best techniques to use it . Always be apart from crowd don\\u2019t be a part of crowd.", "contribution": "Good inspiring thoughts", "intro": "Learner to do better in life"}',
  '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8d51d68f97620ef53299291362bbeb44a7b301fdd284bddc4917554e95456ba4/IMG_20170521_WA0013.jpg", "scribbleSpace": "I like to write, understand human emotions, watch Netflix, Foodie, Bubbling with Creative ideas. Like to live in my space while influencing other\'s", "contribution": "Motivate, Help Wake up, Help to present talent, Help presenting content creatively", "intro": "Exploring life"}',
  '{"name": "Shital Waray", "place": "Kharghar- Navi Mumbai", "waNumber": "919773190283", "email": "shital.waray@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a6ffff7ef932178d77d27bab43b5163081b0b4e514d7ca1fef71c84154910c41/IMG_20200703_182006.jpg", "scribbleSpace": "BE, MBA but chose to be Homemaker, like to paint n travel, proud mother of a son.", "contribution": "Not sure as of now", "intro": "Happy Homemaker"}',
  '{"name": "Rutuja Dhamale", "place": "Pune soon to be Bangalore", "waNumber": "919591574593", "email": "rutuja.dhamale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7a13c1fa178a67ee544a1491ee961bc3c77946b47195d3caa51264899e918a7f/B1673126_C72C_4E2A_BC57_705B40CCFCDB.jpeg", "scribbleSpace": "I am a striving early riser working in the space of nature education.", "contribution": "Session on human-wildlife conflict", "intro": "Nature educator, naturalist"}',
  '{"name": "Dhananjay Mujumale", "place": "B. T. Kawade Road, Pune", "waNumber": "919765988424", "email": "dhananjay.mujumale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/44ad383a6d2cffd948797ef761fd1c5ee8f22960f25635bf34f7d6b0d5427202/Dhan.JPG", "scribbleSpace": "Software Engineer, Farmer and Yoga Practitioner", "contribution": "My knowledge & experiences about Yoga", "intro": "Yoga Practitioner"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}'
];


// let newData = [
//   '{"name": "Snehal Bari", "place": "Handewadi Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c31190b01f50d1ba3320f53e57c67d19a341ed336ddd15a358cc5756f63a0a12/IMG_20210324_WA0011.jpg", "scribbleSpace": "I like to listen to the people than to speak\\nSelf talkative and over thinking girl\\nNot a usual girl\\nCan be aggressive anytime\\nI love being fit\\nLifetime learner", "contribution": "I can motivate people to be fit", "intro": "Yoga practitioner"}',
//   '{"name": "Arunkumar krishnamoorthy", "place": "Koramangala, Bangalore", "waNumber": "919449804064", "email": "arunram.krish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0d7ab2a3bf3f1fd45c12dcdc312180598e0e5688d4c25eb3e335da61585bddc0/IMG_20210401_134958.jpg", "scribbleSpace": "Looking forward to get that habbit inculcated and have an efficient and productive day", "contribution": "Sing, exercise, motivate", "intro": "Human being"}',
//   '{"name": "Balaji Mankari", "place": "Banglore", "waNumber": "918125864262", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/630687bab5f99e0d0dddf9472da99885a9ed8b39686968514efae5dadd566493/101EFF09_70E8_481A_A4FC_20B8CF327B82.jpeg", "scribbleSpace": "I am director of educational institution and In my free time I will be travelling to Himalayas and I love road trips", "contribution": "Let c what I can contribute", "intro": "Traveller"}',
//   '{"name": "Siddharth Biniwale", "place": "Sinhagad road, Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ef6282ec97a458fc1e5d99860e4192889e7b4791b07ffbd87906de10a1c06c71/IMG_20210430_090722.jpg", "scribbleSpace": "I am a Naturalist and researcher. Wildlife photography and recording bird calls/songs interests me. Exploring wilderness is a way of life for me.", "contribution": "I would like to offer company for morning cycling ride whoever is interested.\\nI would like to share my skills of Birding and photographing birds.", "intro": "Naturalist"}',
//   '{"name": "Mahak Karda", "place": "Sigra Varanasi", "waNumber": "919819427080", "email": "mahakkarda9@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a9fd9445e79f5632df3b1cf6750548d40948f23433806bb0b0136479670f0fbc/PicsArt_05_19_10.11.41.jpg", "scribbleSpace": "Globetrotter and travel buff / Fashionista and trendsetter / Dynamic inspirer / originative and ingenious", "contribution": "Meditation techniques / How to find creative solutions to your problems", "intro": "Illuminating verve"}',
//   '{"name": "Preetee pushpa-prakash", "place": "Aundh pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebd6b848a3d5722d9671716bdfe75fed1990fd965c18b6dca6d35085e585e187/IMG20190706174944.jpg", "scribbleSpace": "Gardening, composting, parenting, writing, storytelling, reading, education, environment, biodiversity", "contribution": "How to do the wet waste composting at home", "intro": "Life-long learner"}',
//   '{"name": "Rajeev Nikumbh", "place": "Goregaon, Mumbai", "waNumber": "919167050101", "email": "rajeevnikumbh@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4a6d9c0eb3af117b7540d7aa87ea2e34c7e4b4bdcf856f5ce68483e7ff492994/IMG_6962.jpg", "scribbleSpace": "A sports enthusiast. Love playing and watching volleyball, football & cricket. Any outdoor activities including trekking, exploring new places are always fun. And yes, good movies and music are definitely a best way to enjoy leisure. An investment banker during the day to earn bread and butter. Though I am not a morning person and becoming the one has been wishful thinking since ages.  So, here I am...Very excited...looking forward to meet you all amazing people and lets together throw off the shackles :)", "contribution": "I am happy to talk about sports - technique and game plans and sports motivation. And Lend my professional experience if it could be of any help.", "intro": "Sports enthusiast"}',
//   '{"name": "Bhoomica Asnani", "place": "Godhbunder Road, Thane West", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/19755160af485aa4889878231b9c01da8e048820fe9fda41ed60afb50069992f/IMG_20210525_094432.jpg", "scribbleSpace": "I live by the following line- Live like a queen or live like you don\'t care who is the queen! I am interested in being happy and spreading happiness. \\nMy hobbies include reading and watching good movies or series.\\nI am a mother of two lovely children. And life has seasoned me by putting me in various roles. \\nI am presently the Vice Principal of an ICSE School in Thane. \\nI love the colour of the sky during a sunrise and sunset!", "contribution": "Have been into the Education industry for last 15 years. So anything out of that experience if anyone wants I am available \\ud83d\\ude00", "intro": "Seasoned Woman"}',
//   '{"name": "Manisha Yadav", "place": "Tavarekere, Bangalore", "waNumber": "918750545040", "email": "maniphilo1202@gmail.com", "photoLine": "https://api.typeform.com/responses/files/191dbef30210ea527256f3aafc4af0ddebda56bac6631984739ced10a8d79eac/IMG20210422130114.jpg", "scribbleSpace": "Looking forward to developing the wake-up habit and learn more about myself. A way to move one step near to oneself.", "contribution": "A few motivational quotes about maintaining health.", "intro": "Philosopher"}',
//   '{"name": "Kavitha", "place": "Kukatpally", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8ce264e077fbdc515897dfbef2a037286e12ba03a61bbb56917b13c98411e1c/IMG_20210405_161228.jpg", "scribbleSpace": "Very normal person", "contribution": "Not much", "intro": "Interest in sports"}',
//   '{"name": "Dhaval (\\u0927\\u0935\\u0932)", "place": "Domlur, Bangalore", "waNumber": "918375029336", "email": "dhavalchudasama@gmail.com", "photoLine": "https://api.typeform.com/responses/files/316f3dd9b2b6b3501f624106e89fbf56e36735db2832bae6b5b11a8206f10b75/A46B3B3E_8DD7_4F07_9E33_14CE65415628_1_201_a.jpeg", "scribbleSpace": "https://dhavalchudasama.com/", "contribution": "Let\'s co-learn during the June camp journey :)", "intro": "Curious Traveller"}',
//   '{"name": "Suvam roy", "place": "Sarjapur Bangalore", "waNumber": "919930385272", "email": "suvam.roy@iiml.org", "photoLine": "https://api.typeform.com/responses/files/ed2334b6c750ed7b936741be5015abcbd6214ae36f8be01fa9aaf10dfe93d47c/Passport_Pic.jpg", "scribbleSpace": "I like to travel. Learn new languages. And I Am a pet parent", "contribution": "None", "intro": "Product guy"}',
//   '{"name": "Rahul Sharma", "place": "Sahakarnagar, Pune", "waNumber": "918390876388", "email": "rahul.symantec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a21228e6f6004fdf5eb652c945c2ae0f1a37b252a23d6e0235997fbf7862a575/image.jpg", "scribbleSpace": "Have been working in IT field field since 15 years out of which I was working in Night shift (9:30 PM - 6:30 AM) for last 3 years. This seems to have messed up my body clock. While I am back to regular working hours  now, my expectation is to get into the habit of waking early and going to bed early.", "contribution": "Managing personal finances.", "intro": "People Leader"}',
//   '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6af067514840735d85652a5c2b980622934d1087f4ba97396f985b036229ee82/pic2.jpg", "scribbleSpace": "Have been trying to make waking up early since a long time. But have been failing consistently. Was greatly inspired by the Robin Sharma\'s book 5 AM club but wasn\'t able to put the waking up habit into practice.\\n\\nI think waking up early can help me a lot in most areas of my life like health and career.", "contribution": "I can tell about basics of podcasting - why you should do podcasting, what you need, how to record and publish, etc.", "intro": "Software Engineer"}',
//   '{"name": "Yogesh Bhosale", "place": "Chinchwad Pune", "waNumber": "919960449066", "email": "yogesh.bhosale1985@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2e85a69b437200eae09137e9db69c74d3b5b9aa3762f5618417ff46d2ccd3d3a/SAVE_20210220_230213.jpg", "scribbleSpace": "I like to read,travel and help other people. My expectations are I will make a habbit to wake up 5am everyday with this programme.!", "contribution": "Not sure but would like to contribute.", "intro": "Travel Freak"}',
//   '{"name": "Yamini", "place": "Chennai", "waNumber": "919940112513", "email": "Yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0b5090c3bd78f6ffaf0e7068f5a83b7639ecb93ce82ff67a02317c2799014fad/IMG_20210530_WA0000.jpg", "scribbleSpace": "Aspire to wake up early and feel energetic. Like the morning freshness", "contribution": "positive quote", "intro": "Fitness freak. Love you"}',
//   '{"name": "Anusha Yeruva", "place": "Hyderbad", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a114c6a45e36895d6546cfc944140aeef7410bd5bc6f78270a744e149b632689/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living.\\n\\nI love nature walks, take fresh air and organic diet,  which helps me to he fit and healthy.\\n\\nI am looking forward to know myself and my intrests with the help of this club.", "contribution": "I am not sure yet, but I can motivate people by my writings, yet looking forward to do something new which can help me know myself well.", "intro": "I am a yoga practitioner and  a nature lover."}',
//   '{"name": "Kalpesh Dhruve", "place": "Pimple Saudagar, Pune", "waNumber": "919923145886", "email": "kdhruve2@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea83a0c26ddf8b60eb285cbadb84e08531c0a59f7fa76597a26ece43fc7106a6/20210102_120936.jpg", "scribbleSpace": "Outdoor Sports, Making short phrases/Rhymes, Working towards redeeming myself", "contribution": "Financial, Investment Advisory,", "intro": "Practising Chartered Accountant"}',
//   '{"name": "Manish Kulkarni", "place": "Chinchwad Pune 33", "waNumber": "918806562999", "email": "manishexc@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2104daa033e6dd3f6bc68f6ad11166f2e8525ec318b35ea15c0cc2ce869b02c5/IMG_20200704_WA0037.jpg", "scribbleSpace": "Want to take out some time for self", "contribution": "Will try to give some different prospective", "intro": "The family man"}',
//   '{"name": "Seema Kalantri", "place": "Bopal, Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/41a1e8e834ee6c65d17e926c1c537563e169583fc32401c8726b70ad60ac84a0/IMG_20210101_WA0004.jpg", "scribbleSpace": "Interests - Writing\\nIssues - Irratic work schedule", "contribution": "Professional Development in Information Technology area,\\nSelf Acceptance with Vitiligo", "intro": "Working Woman"}',
//   '{"name": "Renu Shirokha", "place": "Mumbai", "waNumber": "919004087387", "email": "rshirokha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6f0139a6cdcff3e995d15abce2ab2cf40095526fd4c2e1819956cce7af0ccb58/603BFBEB_4733_4023_B4C8_C84F32DD982A.jpeg", "scribbleSpace": "I m architect as well as artist. My hobbies are reading, travelling and playing with my dog. My plans are concentrate my growth in self development as much as possible got this year.. looking forward to meet you all soon", "contribution": "Well, i would like share the more we look within ourself .. everything falls on it places on outside", "intro": "Architect & artist"}',
//   '{"name": "Siddharth Biniwale", "place": "Sinhagad road", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1167d0c2e3e0a9948d7fa0c703cbef6353f64f3d213dc71eb13fd304549c130b/IMG_20210430_090722.jpg", "scribbleSpace": "I am a Naturalist. Wildlife photography, recording bird calls/songs interests me.", "contribution": "I can teach Suryanamaskar. \\n\\nI would like to give company for morning cycling ride if anyone interested.", "intro": "Naturalist"}',
//   '{"name": "Raman Bhatia", "place": "Gurgaon", "waNumber": "919654135441", "email": "toramanbhatia@gmail.com", "photoLine": "https://api.typeform.com/responses/files/68e28ba37cc301b96331af279a8c37f30a9b3c5f3b387ae7c2cd97e34142e624/9C67A19D_99B6_4669_A3A0_5C1A64CC2BB9.jpeg", "scribbleSpace": "Better be awake till 5 am", "contribution": "Life is short, make the most of it", "intro": "Sleep Lover"}',
//   '{"name": "Shilpa Gaur", "place": "Makrana Rajasthan", "waNumber": "919794378287", "email": "shilpa020489@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c34a36457b354f74fc0d396098e0aaa08f3e6d6e7f0c8746ea785584143142/E0D59C16_1EB9_45FC_9C1D_BB89F55CDE93.jpeg", "scribbleSpace": "Reading, Want to get fit and get more done everyday", "contribution": "Functionally I can help in marketing and strategy related things. I work with a gaming studio so anyone looking to understand about this area can connect with me. I can help buddy up with people in micro projects for a week ( like finishing a small online course or book in a week or small sprint like not eating junk etc). I can be a good responsibility partner.", "intro": "Habitual Reader"}',
//   '{"name": "Umesh Kedari", "place": "Katraj Pune", "waNumber": "919850355691", "email": "umesh.kedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e716e345216a58fdd9f46ef50ef577fba33868fb139f3cea7bbaeb285ae1e1/IMG_20200521_094042.jpg", "scribbleSpace": "Listening Indian classical music, Gardening", "contribution": "Experience", "intro": "Mechanical Engineer"}',
//   '{"name": "Gunjan Aggarwal", "place": "HSR Layout, Bangalore", "waNumber": "919833263624", "email": "gunjan1982@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be5549086170725cb10d3cf7861b268a8976e8601ae8ad98b0d8fe8ceb009a3f/1622406352148681389425.jpg", "scribbleSpace": "Bookworm and movie geek", "contribution": "A new crypto enthusiast", "intro": "Crypto geek"}',
//   '{"name": "Bipin Advant", "place": "Bavdhan Pune", "waNumber": "919881491130", "email": "bipinadvant@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1dc7588f1c554d0744d84f14798d28c44f7996fb4566bfb9acb0f09f680b5227/IMG_20210530_234901.jpg", "scribbleSpace": "Music, Meditation and Yoga, Start your day early", "contribution": "Something on personal development, Experience on gratitude and positive thinking", "intro": "IT professional"}',
//   '{"name": "Whats your name ? ", "place": "Hi {{field:489873bb-e26c-48c7-b3aa-b774c5e46c03}}, Whats your  area, city? ", "waNumber": "ats-app number", "email": "Email ", "photoLine": "Upload your profile photo For club wall", "scribbleSpace": "Scribble space to express yourself: ", "contribution": "Contribution to know what you can offer to others in coming 21 days: ", "intro": "Your intro in two words: "}',
//   '{"name": "Babasaheb", "place": "Pune", "waNumber": "919921002346", "email": "rengebm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/056aab00ac7ffa27aab45febbab09e32c70f893866e097020f58b17c42d4dcda/188978388_4249895371709094_3684729620686480810_n.jpg", "scribbleSpace": "5am", "contribution": "NA", "intro": "Thanks"}',
//   '{"name": "Kavitha", "place": "Kukatpally", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8a3d8cda9c1e14bc1377388c8b1fb9326f913b806d6f710225fd77d339eeb38/IMG_20210405_161228.jpg", "scribbleSpace": "Reading books", "contribution": "Not much", "intro": "Cool person"}',
//   '{"name": "Ayan Sengupta", "place": "Kolkata", "waNumber": "919088186558", "email": "ayanse94@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4302dc702060525344a5487054f5f35ba8787939fce35afa8c0bc5f2119eb32f/IMG_20210404_175012.jpg", "scribbleSpace": "Interests to wake up early morning.", "contribution": "Meditation", "intro": "I am a digital marketing expert"}',
//   '{"name": "Leena Patil", "place": "Mumbai Mulund", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/93a120549a166b0cdf3cd71a92e8de0656c5da275fe09b00e2a8508317a87d1a/IMG_20170505_124933424_HDR.jpg", "scribbleSpace": "Optimistic!!!!", "contribution": "Happy to help!!!", "intro": "Lawyer!!!!!"}',
//   '{"name": "Rakesh", "place": "Gurgaon", "waNumber": "918527399480", "email": "rakeshsirohia@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/485f9da0072345f6c8873ecd13e3b48ab5a57f8875146d7ec20e9897ecbe987a/IMG_20200326_173312.jpg", "scribbleSpace": "Hindi movies and songs", "contribution": "Love to hear and learn from the group", "intro": ".."}',
//   '{"name": "Hema", "place": "Mumbai", "waNumber": "919004261133", "email": "hema.sagare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/248d7e058a125dd56d1d55f9cf223ca956e998861c278714ec463bf5e3392721/PXL_20210513_135425411.jpg", "scribbleSpace": "Hilarious hema as I call myself! Loves to watch birds anywhere they appear:) loves to connect people from different fields..", "contribution": "I can talk about what birds did to me.", "intro": "Bird enthusiast!"}',
//   '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
//   '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://firebasestorage.googleapis.com/v0/b/amclub-cd890.appspot.com/o/campaignPhotos%2FWhatsApp%20Image%202021-04-29%20at%2019.40.19.jpeg?alt=media&token=85657abb-7c45-466e-8c04-7d65ee0a17d6", "scribbleSpace": "Dance, writing occasionally\\n\\n But can\'t think of anything now", "contribution": "Not sure what I am good at... Anything that takes short time :)", "intro": "A humanist teacher"}',
//   '{"name": "Madhura Rale", "place": "Aranyeshwar, Pune", "waNumber": "919860347975", "email": "madhura76@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17aa005a61a9154c4472cff48550c4595d5df2d5316e2ef7844ac04d37fe13a5/Screenshot_20210101_193848_WhatsApp.jpg", "scribbleSpace": "A mad curious person", "contribution": "Not sure... currently in receiving mode", "intro": "Curious biochemist"}',
//   '{"name": "Pranav Patil", "place": "Parvati, Pune", "waNumber": "+919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4645023cc8b8a2e68b03a303d396567534ce5388ca5019141e34d1b5cae6d5a1/20210101_175619.jpg", "scribbleSpace": "5\'amers bring a positive change to the World, and happiness to me! I like to have simple friends. Fitness is passion.", "contribution": "Power ideas, thoughts and experiences", "intro": "Happiness coach"}',
//   '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
//   '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/c226584b7e5d2516a337ebbb3261a5ca5085c9158d0e88a0b4887fceab40a4ef/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional. Married. Quizzing, US Politics and Cooking are my hobbies. Learning to speak Esperanto.", "contribution": "a) The thought process behind setting good quiz questions b) Abour Esperanto and why I am interested in it c) Basics of cooking simple home-made curries d) The US Political System and US Elections", "intro": "Decent Quizzer"}',
//   '{"name": "Pallavi Dhoot", "place": "Senapati Bapat Marg, Pune", "waNumber": "919822008007", "email": "pallavidhoot.nature@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f562524e23ca1314e06cf69db8e1daa6423eb810c7330f9255f984df68d901fa/Screenshot_20210104_042823.png", "scribbleSpace": "I am an early riser.But getting up every day exactly at the same time is something I am trying for the first time.  A dedicated mother and wife, a passionate Naturopath and researcher, a sustainable lifestyle explorer, is how I describe myself.", "contribution": "Can share my knowledge, thoughts and experiences", "intro": "An Explorer"}',
//   '{"name": "Poorva Joshi", "place": "Sinhagad road , Pune", "waNumber": "919604289893", "email": "poorva98joshi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/41cf37d2d8339f601af614d7b6662640753f3a8e175843c54a8a1c56aa6f4cc4/IMG_20210201_WA0000_2.jpg", "scribbleSpace": "I\'m a medical student , in my last year of college . I\'m studying Ayurveda. Honestly I\'ve never been someone who wakes up early . It really is a task for me \\ud83d\\ude1band I have this habit of snoozing my alarms and I end up sleeping again . I realise that I\'m preaching something that I\'m not practicing myself as a doctor ,inspite of knowing the importance of waking up early.  Hence I\'m more than happy and excited to be a part of the 5 am club!", "contribution": "I can talk about the routine or the \'dincharya\' that\'s mentioned in the ayurvedic texts", "intro": "Ayurveda student"}',
//   '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyashnagayach@rediffmail.com", "photoLine": "https://dd-reciepts.s3.ap-south-1.amazonaws.com/5am/WhatsApp+Image+2021-05-31+at+15.14.33.jpeg", "scribbleSpace": "An avid sports lover; likes reading (mostly non-fiction) ; photography is an interest; exploring myself and spirituality", "contribution": "Perspectives from the sports world; Structured presentations ; Lessons I learnt reading some interesting stuff", "intro": "Pragmatic Believer"}',
//   '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe16a6ce8c705ae59745549a0cadf4e2d7d2872eb339264a1f3fc0e491d6153f/1610697823797.jpg", "scribbleSpace": "Chartered accountant  by profession.. Fitness, art, music is my passion..", "contribution": "Importance of being fit.", "intro": "Chartered accountant by profession.. Fitness, art and music is my passion.."}',
//   '{"name": "Debanjana Sinha", "place": "Domlur, Bangalore", "waNumber": "919911777199", "email": "debu.piyu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea4662680ab24a3542356d467a3c544159ed5a711c11e32fece168fabf5e5f9a/IMG_20190913_184013.jpg", "scribbleSpace": "Interest- Stoicism, Japan, Philosophy, fountain pens, stationery\\nExpectations- hope to be a morning person through this person", "contribution": "General trivia/ quizzes, Stoic philosophy, historical trivia, travelling tips", "intro": "Memento Mori"}',
//   '{"name": "Leena Patil", "place": "Mulund East", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/e898a02e03939698e31d8e9f44dcd702b1398dff2bc00eaca9ceb4ca9fbda738/IMG_20210123_183046.jpg", "scribbleSpace": "I am an optimistic person! Want to be always happy and makes other happy too!", "contribution": "Want to help everyone in whatever manner I can!", "intro": "I am a lawyer!"}',
//   '{"name": "Manisha Yadav", "place": "Panchgani, Mahabaleshwar", "waNumber": "918750545040", "email": "maniphilo1202@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3cb802378c2023d2cc4cc99d6ee4dfe8ed42a6b43f1bdf604b61e7f8bf7a7959/IMG20210324100110.jpg", "scribbleSpace": "I like listening music, little dancing, lots of talking. Also, I am very excited to develope this amazing habit of 5 am", "contribution": "Self love Quotes", "intro": "Philosopher"}'
// ];


var takenOut = [
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Arunkumar krishnamoorthy", "place": "Koramangala, Bangalore", "waNumber": "919449804064", "email": "arunram.krish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0d7ab2a3bf3f1fd45c12dcdc312180598e0e5688d4c25eb3e335da61585bddc0/IMG_20210401_134958.jpg", "scribbleSpace": "Looking forward to get that habbit inculcated and have an efficient and productive day", "contribution": "Sing, exercise, motivate", "intro": "Human being"}',
  '{"name": "Babasaheb", "place": "Pune", "waNumber": "919921002346", "email": "rengebm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/056aab00ac7ffa27aab45febbab09e32c70f893866e097020f58b17c42d4dcda/188978388_4249895371709094_3684729620686480810_n.jpg", "scribbleSpace": "5am", "contribution": "NA", "intro": "Thanks"}',
  '{"name": "Manisha Bhosale", "place": "Kothrud Pune", "waNumber": "919890039898", "email": "manisha_bhosale@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/1e4bbc7211010e211bda51616531b08e07a837838e0fa54dd183685ab434e3d8/8F9D9EC9_8A9E_4C6F_A639_A8EA95954313.png", "scribbleSpace": "Certified Yoga Trainer. Took up yoga as an activity to improve health and also as a fruitful activity after quitting the job. Presently the routine is a bit disturbed due to minor health issues and also lack of discipline. Trying to get my energy and health back by following Pallavi Dhoot\\u2019s HealthyMe programme and making dietary and lifestyle changes, trying to wake up early being one of them", "contribution": "I could try to answer any queries related to yogasanas, pranayama and shuddhi kriya", "intro": "Yoga practitioner"}',
]